<template>
  <div
    class="bookingDashboard bookingDashboard--list"
    :class="'bookingDashboard--' + dashboardType"
  >
    <div class="bookingDashboard__header">
      <div class="bookingDashboard__title">{{ subtitle.replace('-', ' ') }}</div>
      <router-link v-if="dashboardType === 'admin'" class="signUpDashboard__button" to="">
        <span
        @click="exportToExcel()"
        class="text">Export</span>
      </router-link>
    </div>
    <div class="bookingDashboard__table">
      <div class="bookingDashboard__table-header">
        <div class="bookingDashboard__table-cell">№</div>
        <div class="bookingDashboard__table-cell">Booking Number</div>
        <div class="bookingDashboard__table-cell">Flyer Name</div>
        <div class="bookingDashboard__table-cell">Offset Percentage</div>
        <div class="bookingDashboard__table-cell">Offset Total</div>
      </div>
      <div class="bookingDashboard__table-body js-customScroll">
        <vue-scroll :ops="ops">
          <div class="bookingDashboard__table-row" v-for="(item, index) of list" :key="index">
            <div class="bookingDashboard__table-cell">
              <div class="bookingDashboard__table-index">{{list.length - index}}</div>
            </div>
            <div class="bookingDashboard__table-cell">
              <span>{{item.booking.number}}</span>
            </div>
            <div class="bookingDashboard__table-cell">
              <a
                class="bookingDashboard__table-name"
                @click="showFlyerDetailsPopup(item.flyer)"

              >
                {{item.flyer.firstName + ' ' + item.flyer.lastName}}
              </a>
            </div>
            <div class="bookingDashboard__table-cell">
              <span>{{item.offsetPercentage + "%"}}</span>
            </div>
            <div class="bookingDashboard__table-cell">
              <span>{{"$" + item.offsetCost}}</span>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <FlyerDetailsPopup/>
  </div>
</template>

<script>
  import { reportsAPI } from '@/api/reports'
  import FlyerDetailsPopup from '@/components/common/modals/FlyerDetailsPopup'
  import '../SignUpDashboard/SignUpDashboard.styl'

  export default {
    props: ['dashboardType', 'subtitle'],
    name: 'OffsetPurchases',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    mounted () {
    this.$store.commit('setOffsetPurchases');
    },
    computed: {
      list () {
        return this.$store.state.booking.offsetPurchases
      },
    },
    components: {
      FlyerDetailsPopup,
    },
    methods: {
      showFlyerDetailsPopup (account) {
        console.log('show clicked')
        this.$modal.show('FlyerDetailsPopup', {additionalInfo: account})
      },
      async exportToExcel () {
        try {
          const { data } = await reportsAPI.offsetPurchases();
          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'offsetPurchases_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e);
        }
      },
    },
  }
</script>
